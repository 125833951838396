<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12 pt-0 pb-2">
        <h1>
          Credit note #{{ creditNote.number }}
          <span class="float-right">View</span>
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12 pt-0 pb-0">
        <router-link
          custom v-slot="{ navigate }"
          :to="`/invoices/credit-note-print-view/${creditNote.id}`">
          <v-btn
            small
            @click="navigate"
            @keypress.enter="navigate"
            role="link"
            class="relative"
            style="top:-4px">
              Print View
          </v-btn>
        </router-link>
        &nbsp;
        <v-btn
          small
          @click="exportCreditNote()"
          class="relative"
          style="top:-4px">
            Export to Excel
        </v-btn>
        &nbsp;
        <v-btn style="top:-4px" small @click="saveChanges">Save Changes</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-6">
        <v-text-field v-model="creditNote.customer"></v-text-field>
        <span v-if="creditNote.customerAddressOne">
          <v-text-field v-model="creditNote.customerAddressOne" label="Address line one"></v-text-field>
        </span>
        <span v-if="creditNote.customerAddressTwo">
          <v-text-field v-model="creditNote.customerAddressTwo" label="Address line two"></v-text-field>
        </span>
        <span v-if="creditNote.customerAddressThree">
          <v-text-field v-model="creditNote.customerAddressThree" label="Address line three"></v-text-field>
        </span>
        <span v-if="creditNote.customerAddressFour">
          <v-text-field v-model="creditNote.customerAddressFour" label="Address line four"></v-text-field>
        </span>
        <span v-if="creditNote.customerAddressFive">
          <v-text-field v-model="creditNote.customerAddressFive" label="Address line five"></v-text-field>
        </span>
        <span v-if="creditNote.customerPostcode">
          <v-text-field v-model="creditNote.customerPostcode" label="Postcode"></v-text-field>
        </span>
      </v-col>
      <v-col class="col-6">
        <p>
          Document number: {{ creditNote.number }}<br />
          Date/Tax Point:
          <v-dialog
          ref="dialog"
          v-model="modal"
          persistent
          width="390px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="creditNote.date"
              label="Date / Tax point"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            @input="modal = false"
          >
          </v-date-picker>
        </v-dialog><br />
          Credit note reference: <v-text-field v-model="creditNote.reference"></v-text-field><br />
          Reason for the credit note: <v-text-field v-model="creditNote.reason"></v-text-field><br />
          Account reference: {{ creditNote.accountReference }}
        </p>
      </v-col>
    </v-row>
    <v-row class="pt-5">
      <v-col class="col-12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th style="width: 60%" class="text-left">Description</th>
                <th style="width: 10%" class="text-left">Quantity</th>
                <th style="width: 10%" class="text-left">Amount</th>
                <th style="width: 10%" class="text-left">Line total</th>
                <th style="width: 10%">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(line, index) in creditNote.lines" :key="index">
                <td>
                  <v-text-field
                    dense  
                    v-model="line.description"></v-text-field>
                </td>
                <td>
                  <v-text-field
                    dense
                    type="number"
                    step="0.01"
                    v-model.number="line.quantity"></v-text-field>
                </td>
                <td>
                  <v-text-field
                    dense
                    type="number"
                    step="0.01"
                    v-model="line.priceInPounds"></v-text-field>
                </td>
                <td>
                  {{ (line.quantity * line.priceInPounds) | currency }}
                </td>
                <td>
                  <v-btn
                    @click="updateLine(index)"
                    x-small>Save</v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-6">
        <label>Descriptive notes (appear below the lines on the credit note):</label>
        <vue-editor
          id="descriptionNotes"
          :editor-toolbar="customToolbar"
          v-model="creditNote.description" />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-6">
        <p>
          <v-text-field v-model="creditNote.siteLocation" label="Delivery location"></v-text-field>
        </p>
        <p>
          <vue-editor
              id="description"
              :editor-toolbar="customToolbar"
              v-model="creditNote.workDescription" />
        </p>
        <p>
          <v-select
            v-model="creditNote.contactId"
            label="Contact"
            :items="contacts"
            item-text="name"
            item-value="id"></v-select>
        </p>
      </v-col>
      <v-col class="col-6">
          <v-simple-table dense>
              <tbody>
                  <tr>
                      <th>Sub-total:</th>
                      <td>{{ creditNote.subTotal | priceInPounds | currency }}</td>
                      <th>Vat:</th>
                      <td>{{ creditNote.vat | priceInPounds | currency }}</td>
                      <th>Total:</th>
                      <td>{{ creditNote.total | priceInPounds | currency }}</td>
                  </tr>
              </tbody>
          </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { VueEditor } from "vue2-editor";
import axios from '../../axios';

export default {
  name: 'InvoicesCreditNoteView',
  components: {
    VueEditor,
  },
  data() {
    return {
      creditNote: {},
      modal: false,
      date: null,
      customToolbar: [
        ["bold", "italic", "underline"],
      ],
      contacts: [],
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  watch: {
    date () {
      this.creditNote.date = this.formatDate(this.date);
    },
  },
  methods: {
    saveChanges() {
      const postData = {};
      postData.creditNote = this.creditNote;
      axios.post(`/creditNotes/saveChanges.json?token=${this.token}`, postData);
    },
    updateLine(index) {
      const line = this.creditNote.lines[index];
      axios.post(`/creditNoteLines/update.json?token=${this.token}`, line);
    },
    getCreditNote() {
      axios.get(`/creditNotes/getSingle/${this.$route.params.id}.json?token=${this.token}`)
      .then((response) => {
        this.creditNote = response.data.creditNote;
        this.date = response.data.creditNote.date;
        this.creditNote.date = this.formatDate(this.date);
        this.getContacts();
      });
    },
    exportCreditNote() {
      axios.get(`/spreadsheets/creditNote/${this.creditNote.id}.json?token=${this.token}`, {responseType:'blob'})
        .then((response) => {
          const href = URL.createObjectURL(response.data);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', `credit_note_${this.creditNote.number}.xlsx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        });
    },
    formatDate (date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}-${month}-${year}`;
    },
    getContacts() {
      axios.get(`/contacts/getByCustomerId/${this.creditNote.customerId}.json?token=${this.token}`)
      .then((response) => {
        this.contacts = response.data;
      });
    },
  },
  mounted() {
    this.getCreditNote();
  },
};
</script>